.stroke-btn {
  display: inline-block;
  height: var(--btn-height);
  padding: 0 var(--padding-sm);

  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;

  border: 1px solid;
  background: none;
  cursor: pointer;
}

.stroke-btn--white {
  color: white;

    &:hover {
      background-color: rgba(white, 0.1);
    }

}

.stroke-btn--dark-peak {
  color: var(--color-dark-peak);

    &:hover {
      background-color: var(--color-dark-peak-fade-10);
    }

}

.btn {
  display: inline-block;
  height: var(--btn-height);
  padding: 0 var(--padding-sm);

  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  line-height: var(--btn-height);
  // letter-spacing: var(--loos-kerning);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;

  border: none;
  cursor: pointer;
}

.btn--dark-peak {
  color: white;
  background-color: var(--color-dark-peak);

    &:hover {
      background-color: var(--color-dark-peak-hover);
    }

}

.btn--blue-john {
  color: white;
  background-color: var(--color-blue-john);

    &:hover {
      background-color: var(--color-blue-john-hover);
    }

}

.btn--cavern-blue {
  color: white;
  background-color: var(--color-cavern-blue);

    &:hover {
      background-color: var(--color-cavern-blue-hover);
    }

}

.text-btn {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xxs);
  font-weight: var(--loos-medium);
  text-transform: uppercase;
  color: var(--color-dark-peak);
  line-height: 1;

  background: none;

    &:hover {
      color: var(--color-midnight-black);
      text-decoration: underline;
    }

}