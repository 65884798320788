.promo-rows {
  margin: 0 0 var(--padding-xxl);
  background-color: white;
}

.promo-row {
  display: grid;

    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

}

.promo-row__section {

    @include mq(screen-sm) {
      display: flex;
    }

}

.promo-row__section--feed {
  
  @include mq(screen-sm) {
    width: 100%;
  }

}

.promo-row__section--img {
  aspect-ratio: 16/9;

    @include mq(screen-sm) {
      aspect-ratio: auto;
    }

}

.promo-row:nth-of-type(odd) {
  
  .promo-row__section--feed {
    
    @include mq(screen-sm) {
      justify-content: flex-end;
    }

  }

}

.promo-row:nth-of-type(even) {
  
  @include mq(screen-sm) {
    
    .promo-row__section--feed {
      order: 2;
    }

  }

}

.promo-row__products {
  padding: var(--padding-xl) var(--padding-md);
    
    @include mq(screen-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(screen-xl) {
      width: var(--mq-sm);
    }

}

.promo-row__cta {
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.promo-row__img-outer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

    img {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }

    &:after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      
      width: 100%;
      height: 100%;
    }

}

.promo-row__text {
  position: relative;
  z-index: 2;
  display: grid;
  place-items: center;
  align-content: center;
  gap: var(--padding-lg);
  width: 100%;
  height: 100%;
  padding: var(--padding-xl);

  text-align: center;

    @include mq(screen-sm) {
      padding: pxRem(100) var(--padding-xl) calc(var(--btn-height) + var(--padding-lg));
    }

    @include mq(screen-lg) {
      width: 75%;
    }

    .stroke-btn {
      
      @include mq(screen-sm) {
        position: absolute;
        bottom: var(--padding-lg);
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
      }

    }

    h2 {
      font-family: var(--loos-extended);
      font-size: var(--font-size-xl);
      text-transform: uppercase;
      line-height: var(--loos-line-height);
      letter-spacing: var(--loos-kerning);
      font-weight: var(--font-weight-bold);

        i {
          font-family: var(--loos-compressed);
          font-style: normal;
          font-weight: normal;
        }

    }


}

// Variants

.promo-row__cta--dark {
  
  .promo-row__img-outer:after {
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    opacity: 0.95;
  }

  .promo-row__text {
    color: white;
  }

  .stroke-btn {
    color: white;
  }

  .stroke-btn:hover {
    background-color: rgba(white, 0.1);
  }

}

.promo-row__cta--light {
  
  .promo-row__img-outer:after {
    background: linear-gradient(0deg, rgba(255,255,255,0.85) 0%, rgba(255,255,255,0) 100%);
  }

  .promo-row__text {
    //color: white;
  }

  .stroke-btn {
    color: var(--color-dark-peak);
  }

  .stroke-btn:hover {
    background-color: rgba(50,65,66, 0.1);
  }

}




