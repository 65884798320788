.similar-beers {
  display: grid;
  gap: var(--padding-md);
  padding: 0 0 var(--padding-xl);
}

.similar-beers__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--padding-xs);
  padding: 0 0 var(--padding-md);

  border-bottom: 1px solid var(--color-grey);
}