.product-bg {
  position: absolute;
  z-index: 2;
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;

    &:after {
      content: "";

      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: 2;
      
      width: 100%;
      height: 75%;

      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    }

}

.product-bg__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  display: block;
  width: 100%;
  height: auto;
  object-position: top center;

  opacity: 0.6;
  transition: all 0.5s ease-out;

    &.has-faded {
      opacity: 1;
      transition: all 0.5s ease-in;
    }
}

.product-content {
  display: grid;
  gap: var(--padding-xl);

  position: relative;
  z-index: 2;
  padding: var(--paint-daub-padding) 0 0;
}

.product-hero {
  display: grid;
  align-items: start;
  gap: var(--padding-lg);

    @include mq(screen-sm) {
      grid-template-columns: 1fr 3fr;
    }

    @include mq(screen-lg) {
      grid-template-columns: 1fr 1.5fr;
    }

}

.product-img-outer {
  position: relative;
  display: grid;
  place-items: center;
}

.product-img {
  position: relative;
  z-index: 1;

  display: block;
  width: auto;
  height: clamp(17.5rem, 10.8333rem + 33.3333vw, 37.5000rem); /* 280px @ 320px > 600px @ 1280px */
}

.product-desc {
  display: grid;
  width: 100%;
  justify-items: start;
}

.product-info {
  display: grid;
  gap: var(--padding-xl);
  padding: var(--padding-xl) 0 0;

  border-top: 1px solid var(--color-grey-light);

    @include mq(screen-sm) {
      padding: var(--padding-xl) var(--padding-md) var(--padding-lg);

      background-color: rgba(255,255,255,0.67);
      border: none;
    }

    @include mq(screen-lg) {
      padding: var(--padding-xl) var(--padding-lg) var(--padding-lg);
    }

}

.product-attr {
  @extend .title;
  @extend .title--sm;
}

// PRODUCT VARIANT GRID

.product-variants {
  padding: var(--padding-md);

  background-color: var(--color-light-peak);

    @include mq(screen-sm) {
      background-color: white;
    }

}

.variant {
  width: 100%;
  border-collapse: collapse;

    tr {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    tr:not(:last-child) {
      td[data-title="Pack size"],
      td[data-title="Price"],
      td[data-title="quantity"] {
        
        @include mq(screen-md) {
          padding: 0 0 var(--padding-lg);
        }

      }

      td[data-title="Action"] {
        
        @include mq(screen-md) {
          padding: 0 0 var(--padding-lg) var(--padding-lg);
        }

      }

    }

    tr:last-child {

      td[data-title="Action"] {
        padding: var(--padding-md) 0 0;
        
          @include mq(screen-md) {
            padding: 0 0 0 var(--padding-lg);
          }

      }

    }

    td[data-title="Pack size"],
    td[data-title="Price"],
    td[data-title="quantity"],
    td[data-title="Action"] {
      display: block;
      // padding: var(--padding-sm) 0;
    }

    td[data-title="Pack size"],
    td[data-title="quantity"] {
      width: 33.333%;

        @include mq(screen-md) {
          width: 20%;
        }

    }

    td[data-title="Price"] {
      width: 33.333%;

        @include mq(screen-md) {
          width: 25%;
        }

    }

    td[data-title="Action"] {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: var(--padding-md) 0 var(--padding-lg);

        @include mq(screen-md) {
          width: 35%;
          justify-content: flex-start;
          padding: 0 0 0 var(--padding-md);
        }

        button {
          width: 100%;

            @include mq(screen-md) {
              width: auto;
            }
        }

    }

}

td[data-title="Pack size"],
td[data-title="Price"],
td[data-title="Action"] > button {
  font-family: var(--loos-compressed);
  font-size: var(--font-size-sm);
  letter-spacing: var(--loos-kerning);
  text-transform: uppercase;
}

// td[data-title="Pack size"] {
//   width: 20%;
// }

td[data-title="Price"] {
  // width: 25%;
  color: var(--color-dark-peak);

    del {
      padding: 0 0.25rem 0 0;

      font-size: 75%;
      color: var(--color-grey);
    }

    ins {
      color: var(--color-error);
      text-decoration: none;
    }


}

td[data-title="quantity"] {
  // width: 20%;

    > div {
      display: flex;
    }

}

td[data-title="Action"] {
  width: 35%;
}

.qty-count {
  display: block;
  width: var(--btn-height);
  height: var(--btn-height);

  background: white;
  border: none;

  font-size: var(--font-size-md);
  text-align: center;
  color: var(--color-dark-peak);
  line-height: pxRem(40);

    @include mq(screen-sm) {
      background: var(--color-light-peak);
    }

    &:hover,
    &:focus {
      outline: none;
      color: var(--color-dark-peak-hover);
    }

}

.input-text {
  width: var(--btn-height);
  height: var(--btn-height);

  color: var(--color-cavern-blue);
  text-align: center;

  border: 1px solid var(--color-cavern-blue);

    &:focus {
      outline: none;
      border: 3px solid var(--color-blue-john);
    }

}

.product-variants,
.product-quantity {
  
  .input-text {
    font-family: var(--loos-compressed);
    letter-spacing: var(--loos-kerning);
    text-transform: uppercase;
  }

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

td[data-title="Action"] {
  // width: pxRem(150);
  // padding: 0 40px 0 0;
}

td[data-title="Action"] > button {
  position: relative;

  display: inline-block;
  // width: pxRem(90);
  height: var(--btn-height);
  padding: 0 var(--padding-md);

  font-family: var(--loos-compressed);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  line-height: var(--btn-height);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: white;
  background-color: var(--color-dark-peak);

    &:hover {
      background-color: var(--color-dark-peak-hover);
    }

}

td[data-title="Action"] > button:disabled {
  cursor: not-allowed;
  background-color: #F9E6E2;
  color: #D35031;
}

.spinner-wrap {
  display: none;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.pvt-icon-spinner {
  display: block;

  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;

  opacity: 0.5;
}

@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

// PRODUCT EXTRAS

.product-details {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: var(--padding-xl);
}

.product-details-outer {

  .product-details__block {
    display: grid;
    gap: var(--padding-sm);
    width: 100%;
  }

  .product-details__block:nth-child(1) {
    
    @include mq(screen-xs) {
      width: calc(50% - var(--padding-xl));
    }

    @include mq(screen-md) {
      width: calc(35% - var(--padding-xl));
    }

  }

  .product-details__block:nth-child(2) {
    
    @include mq(screen-xs) {
      width: 50%;
    }

    @include mq(screen-md) {
      width: 30%;
    }

  }

  .product-details__block:nth-child(3) {
    
    @include mq(screen-md) {
      width: calc(35% - var(--padding-xl));
    }

  }

}

.product-taste-ratings {
  display: grid;
  gap: var(--padding-sm);
  padding: 0 0 var(--padding-sm);

  border-bottom: 1px solid var(--color-grey);
}

.product-taste-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-taste-rating__label {
  font-family: var(--loos-extended);
  text-transform: uppercase;
}

.product-taste-rating__score {
  display: flex;
  gap: 5px;
}

.product-taste-rating__pill {
  display: block;
  width: 3px;
  height: 15px;

  background-color: var(--color-dark-peak);
  opacity: 0.25;
}

@for $i from 1 through 10 {
  
  .product-taste-rating__score--#{$i} {
    
    .product-taste-rating__pill:nth-child(-n+#{$i}) {
      opacity: 1;
    }

  }

}

.product-dietary-info {
  text-align: center;

    p {
      font-size: var(--font-size-xs);
      text-align: center;
    }

    strong {
      font-weight: var(--font-weight-bold);
    }

}

.product-color {
  position: relative;
  display: grid;
  place-items: center;
  padding: var(--padding-lg);

  text-align: center;

    p {
      position: relative;
      z-index: 2;
      font-family: var(--loos-extended);
      font-size: var(--font-size-lg);
      text-transform: uppercase;
      letter-spacing: var(--loos-kerning);
      line-height: var(--loos-line-height);
      color: white;
    }

    p i {
      font-family: var(--loos-compressed);
      font-style: normal;
    }

    &:after {
      content: "";

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      
      width: 100%;
      height: 100%;

      background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    }
}

.product-details-outer .product-color {
  width: 100%;
  height: pxRem(150);

    @include mq(screen-sm) {
      height: auto;
      aspect-ratio: 3/2;
    }
}

@each $index, $color in $color-profiles {

  .product-color--#{$index} {
    background-color: $color;
  }

}

.product-recommendations {
  display: grid;
  gap: var(--padding-xs);
}

.product-recommendation {
  display: flex;

  border: 1px solid var(--color-dark-peak);
  background-color: white;

    @include mq(screen-lg) {
      border: none;
    }

}

.product-recommendation__label-cell {
  display: grid;
  flex-shrink: 0;
  width: 30%;
  padding: var(--padding-sm) var(--padding-xs);
  background-color: var(--color-dark-peak);

  text-align: center;
}

.product-recommendation__label {
  font-family: var(--loos-extended);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  color: white;
}

.product-recommendation__value-cell {
  display: grid;
  align-items: center;
  flex: 1;
  padding: var(--padding-sm);
}

.product-recommendation__value {
  font-size: var(--font-size-xs);
}

// PRODUCT GALLERY

.product-gallery {
  display: grid;
  
    @include mq(screen-xxs) {
      grid-template-columns: repeat(3, 1fr);
    }

}

.product-gallery__img {
  position: relative;
  aspect-ratio: 1/1;

    img {
      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
    }

}







