.age-popup-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;

  display: grid;
  place-items: center;

  width: 100%;
  height: 100%;

  background-color: var(--color-light-peak);
}

.age-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--padding-md);
  padding: var(--padding-xl) var(--padding-lg);

  text-align: center;
  background-color: white;

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--padding-md);
    }

}

.age-popup__logo {
  display: block;
  width: pxRem(100);

    svg {
      fill: var(--color-dark-peak);
    }

    @include mq(screen-sm) {
      width: pxRem(150);
      margin: 0;
    }

    @include mq(screen-lg) {
      width: pxRem(200);
      margin: 0;
    }

}

.dob-error {
  display: none;

  padding: var(--padding-md);
  border: 1px solid #D35031;
  background-color: #F9E6E2;

    p {
      font-weight: var(--font-weight-bold);
    }

    &.is-visible {
      display: block;
    }


}

.age-popup-paint-daub svg {
  width: 100%;
  height: auto;
  fill: white;
}